/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import './Careers.scss';
import '../styles/main.scss';

const Careers = () => (
  <div className="careers">
    <span className="sub-heading">CAREERS</span>
    <div className="headline">
      we are
      <br />
      {' '}
      HIRING!
    </div>
    <div className="career-data flex space-evenly center-align">
      <div className="career-list">
        <h1>OPEN POSITIONS</h1>
        <h2>APPLY NOW!</h2>
        <p>Send CV and cover letter to info@biokoatlantic.com</p>
      </div>
      <div className="career-action">
        <button type="button" className="btn1">Easy Apply</button>
      </div>
    </div>

  </div>
);

export default Careers;
