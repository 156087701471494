/* eslint-disable linebreak-style */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import {
  Container, Navbar, Nav,
} from 'react-bootstrap';
import './Header.scss';
import '../styles/main.scss';

const Header = () => (
  <Navbar className="header navbar navbar-inverse navbar-fixed-top" expand="lg">
    <Container>
      <LinkContainer to="/">
        <Navbar.Brand>
          <img className="logo-img" src="./images/logo.jpg" alt="Baos Multiservice" width="60px" />
          <span className="purple-font ml-2">Baos Multi-service</span>
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <a href="#" className="mr-4">HOME</a>
          <a href="#about" className="mr-4">ABOUT</a>
          <a href="#services" className="mr-4">SERVICES</a>
          <a href="#careers" className="mr-4">CAREERS</a>
          <a href="#contact" className="mr-4">CONTACT</a>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

export default Header;
