/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import './App.css';

import Header from './components/Header/Header';
import About from './components/About/About';
import ServiceList from './components/ServiceList/ServiceList';
// import Contact from './components/Contact/Contact';
import Careers from './components/Careers/Careers';
import Footer from './components/Footer/Footer';
import Welcome from './components/Welcome/Welcome';


function App() {
  return (
    <div className="App">
      <Header />
      <Welcome />
      <span id="services" />
      <ServiceList />
      <span id="about" />
      <About />
      <span id="contact" />
      {/* <Contact /> */}
      <span id="careers" />
      <Careers />
      <Footer />
    </div>
  );
}

export default App;
