/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import './Footer.scss';
import '../styles/main.scss';

const Footer = () => {
  const year = new Date();
  return (
    <div className="footer flex space-evenly center-align">
      <div className="copyright">
        &copy;
        {year.getFullYear()}
&nbsp;Bioko Atlantic Oilfield Services.
      </div>
      <div className="social-links" />
    </div>
  );
};

export default Footer;
