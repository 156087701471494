import React from 'react';
import './Slider.scss';
import '../styles/main.scss';

import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';

const Slide = () => {
  const slides = [
    {
      title: 'GET RELIABLE INTEGRATED SERVICES',
      description: 'Welcome to BAOS. We are a multi-services International company with primary activities in the Oil and Gas Industry',
      btn: 'More About Us',
      image: 'https://images.pexels.com/photos/1532771/pexels-photo-1532771.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=900&w=400',
    },
    {
      title: 'MANPOWER SUPPLY',
      description: 'We provide skilled and semi-skilled personnel as needed to deliver on a short, medium or long-term project.',
      btn: 'Our Manpower Services',
      image: 'https://images.pexels.com/photos/2760241/pexels-photo-2760241.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=400&w=900',
    },
    {
      title: 'OUR CAREERS',
      description: 'We are looking for professional individuals to join our team',
      btn: 'Job Offers',
      image: 'https://images.pexels.com/photos/1311518/pexels-photo-1311518.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=900&w=400',
    },
  ];
  return (
    <div className="slider flex space-evenly center-align">

      <Slider autoplay="3000" infinite="true">
        {slides.map((slide, index) => (
          <div className="flex column space-evenly center-align" key={index} style={{ background: `url('${slide.image}') no-repeat center center ` }}>
            <button className="btn1">{slide.btn}</button>
          </div>
        ))}
      </Slider>

    </div>
  );
};

export default Slide;
