/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import './Service.scss';
import '../styles/main.scss';

const Service = ({
  image, title, description, link, form,
}) => (
  <div className="service flex column space-between">
    <div className="service-image">
      <img alt="service" src={image} width="100%" />

    </div>
    <div className="service-title sub-title">
      {title}
    </div>
    <div className="service-description justify-text">
      {description}
    </div>
    <div className="action-buttons flex space-between">
      <button className="btn1">
        <a href={link} />
        More Info
      </button>
      <button className="btn1">
        <a href={form} />
        Request service
      </button>
    </div>

  </div>
);

export default Service;
