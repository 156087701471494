/* eslint-disable linebreak-style */
import React from 'react';
import './About.scss';
import '../styles/main.scss';

import Slide from '../Slider/Slider';

const About = () => (
  // eslint-disable-next-line react/jsx-filename-extension
  <div className="about">
    <span className="sub-heading">ABOUT US</span>
    <div className="about-content flex space-evenly wrap">
      <div className="about-text flex column justify-text">
        <p className="content-margin">We are always prepared to assist you with all your needs regarding Manpower supply, Plant Shutdown TAR, Facilities Maintenance, Meet and Greet Logistics Support, Equipment Rentals, Hostelry and General Consultancy. Our staff is ever ready to provide and support you with your requirements.</p>
        <p className="content-margin">In the ever-growing Oil and Gas industry, whether in Exploration, Drilling or Production, Companies are always keen to finding resourceful, capable and reliable personnel who can blend in to their work force with the ease of professionalism.</p>
        <p className="content-margin">Being it a main Operator, a service contractor or a subcontractor one important concern amongst all is to have a reliable working partner that can deliver on time in a safe and professional manner. At BAOS we give priority to those concerns, we are aware of the standards that have been set by the Oil and Gas Industry all over the world, which is why we make Safety, Reliability and Transparency our priority in all dealings.</p>
        <p className="content-margin">BAOS will provide you with a full recruitment service including, pre- contract interview / qualification testing, advert placement, review and description of Local Labor Law regarding employment in the Oil and Gas sector, timely Personnel paycheck processing, medical coverage, VISA,LOIs and Work Permit processing for EXPAT hiring. You can be rest assured when doing business with us.</p>
      </div>
      <div className="about-text justify-text">
        <Slide />
      </div>
    </div>
  </div>
);

export default About;
