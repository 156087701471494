/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import './Welcome.scss';
import '../styles/main.scss';

const Welcome = () => (
  <div id="home" className="welcome">
    <div className="flex space-evenly center-align wrap">
      <div className="welcome-text">
        <div className="welcome-text-main"> RELIABLE INTEGRATED</div>
        {' '}
        <div className="welcome-text-main1">SERVICES</div>
        <div className="welcome-text-sub">YOUR OIL & GAS MATERIAL AND PERSONEL SUPPLY</div>
      </div>

      <div className="welcome-contact">
        <div className="quote">Need a quote?</div>
        <div className="quote-call">Call us :+240 222 644 246 </div>
        <div className="quote-email">Email Us :info@biokoatlantic.com</div>
      </div>
    </div>

  </div>
);

export default Welcome;
