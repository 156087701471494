/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import './ServiceList.scss';
import '../styles/main.scss';

import Service from '../Service/Service';

const ServiceList = () => {
  const services = [
    {
      image: 'https://images.pexels.com/photos/1078884/pexels-photo-1078884.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=300&w=300',
      title: 'Rental Equipment',
      description: 'With the wide range of products that make up our fleet, we offer the flexibility to take care of all your rental needs out of one convenient location.',
      link: '#',
      form: '#',
    },
    {
      image: 'https://images.pexels.com/photos/1145434/pexels-photo-1145434.jpeg?auto=compress&cs=tinysrgb&dpr=2&h300&w=300',
      title: 'Facilities Maintenance',
      description: 'We offer repair and maintenance services to business clients, ranging from national retail and restaurant chains to commercial property managers, landlords and corporate facilities managers.',
      link: '#',
      form: '#',
    },
    {
      image: 'https://images.pexels.com/photos/2760241/pexels-photo-2760241.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=300&w=300',
      title: 'Manpower supply',
      description: 'We provide skilled and semi-skilled personnel as needed to deliver on a short, medium or long-term project. Whether someone is needed at short notice for a temporal job, or you require a complete contract team.',
      link: '#',
      form: '#',
    },
  ];

  const servicedisplay = services.map((service) => (
    <Service
      image={service.image}
      title={service.title}
      description={service.description}
      link={service.link}
      form={service.form}
    />
  ));
  return (
    <div className="services">
      <span className="sub-heading">OUR SERVICES</span>
      <div className="servicelist flex space-evenly wrap">
        {servicedisplay}
      </div>
    </div>
  );
};

export default ServiceList;
