/* eslint-disable linebreak-style */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';

const Mailbox = () => (
  <div style={{ textAlign: 'center', color: 'purple', fontSize: '24px' }}>
    <a href="https://webmail1.hostinger.com/">click here to continue to Mailbox ...</a>
  </div>
);

export default Mailbox;
